import {
    CostInsightsApi,
    ProductInsightsOptions,
    Alert
} from '@backstage-community/plugin-cost-insights';
import { Cost, Entity, Group, MetricData, Project } from '@backstage-community/plugin-cost-insights-common'
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';
import { ResponseError } from '@backstage/errors';
import dateFormat from 'dateformat';
import { parseEntityRef } from '@backstage/catalog-model';
import { get } from 'lodash'; 

export class CostInsightsClient implements CostInsightsApi {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    catalogApi: CatalogApi;

    constructor({ discoveryApi, fetchApi, catalogApi }: { discoveryApi: DiscoveryApi, fetchApi: FetchApi, catalogApi: CatalogApi }) {
        this.discoveryApi = discoveryApi;
        this.fetchApi = fetchApi;
        this.catalogApi = catalogApi;
    }

    private async get<T>(path: string): Promise<T> {
        const baseUrl = await this.discoveryApi.getBaseUrl('cost-insights'); 
    
        const url = `${baseUrl}/${path}`;
    
        const response = await this.fetchApi.fetch(url);
    
        if (!response.ok) {
          throw await ResponseError.fromResponse(response);
        }
    
        return response.json() as Promise<T>;
      }

    async getLastCompleteBillingDate(): Promise<string> {
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)); 

        return dateFormat(yesterday, 'yyyy-mm-dd');
    }

    async getUserGroups(userId: string): Promise<Group[]> {
        return (
            await this.catalogApi.getEntities({
                filter: {
                    kind: 'Group',
                    ['relations.hasMember']: [`user:default/${userId}`],
                },
                fields: ['metadata.name', 'spec.profile.displayName'],
            })
        ).items.map(e => {
            return {
                id: `group:${e.metadata.name}`,
                name: get(e, 'spec.profile.displayName', e.metadata.name) as string,
            };
        });
    }

    async getGroupProjects(group: string): Promise<Project[]> {
        console.log(group);
        return [];
    }

    async getAlerts(group: string): Promise<Alert[]> {
        console.log(group);
        return [];
    }

    async getDailyMetricData(
        metric: string,
        intervals: string,
    ): Promise<MetricData> {
        console.log('getDailyMetricData', metric, intervals);
        return {
            id: 'remove-me',
            format: 'number',
            aggregation: [],
            change: {
                ratio: 0,
                amount: 0,
            },
        };
    }

    async getGroupDailyCost(group: string, intervals: string): Promise<Cost> {
        return this.getCatalogEntityDailyCost(group, intervals);
    }

    async getProjectDailyCost(project: string, intervals: string): Promise<Cost> {
        console.log(project);
        console.log(intervals);
        return {
            id: 'remove-me',
            aggregation: [],
            change: {
                ratio: 0,
                amount: 0,
            },
        };
    }

    async getCatalogEntityDailyCost(
        catalogEntityRef: string,
        intervals: string,
    ): Promise<Cost> {
        const entityRef = parseEntityRef(catalogEntityRef);
        const urlSegment = `v1/entity/${entityRef.namespace}/${entityRef.kind}/${
        entityRef.name
        }/${encodeURIComponent(intervals)}`;

        const service = await this.get<Cost>(urlSegment);

        return service;
    }

    async getProductInsights(options: ProductInsightsOptions): Promise<Entity> {
        console.log(options);
        return {
            id: 'remove-me',
            aggregation: [0, 0],
            change: {
                ratio: 0,
                amount: 0,
            },
            entities: {},
        };
    }
}